.background-wrapper.profile.japan {
  background: url(../assets/images/NUL-map-transition.jpg) center center;
  background-repeat: no-repeat;
  background-color: white;
}

.background-wrapper.profile.china {
  background: url(../assets/images/NUL-map-china.jpg) center center;
  background-repeat: no-repeat;
  background-color: white;
}

.map img {
  width: 230px;
}

.check-img {
  width: 130px;
}

.bottom {
  order: 2;
}

.phone-country {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
}

.phone-country span {
  position: relative;
  top: -2px;
}

.phone-country .col:first-of-type {
  border: 1px solid rgb(154 155 156 / 30%);
  padding: 10px 10px 8px 10px;
  border-radius: 3px;
  cursor: not-allowed;
}

.mig-details p {
  font-size: 14px;
}

/* Language Selector */

.lang-text {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  pointer-events: none;
}

/* Flag Header */

.intro-flag {
  width: 720px;
}

.intro-flag {
  color: white;
  margin-top: -20px;
  margin-bottom: 40px;
  width: calc(100% + 80px);
  padding: 100px 70px 20px;
  border-radius: 17px 17px 0px 0px;
  background: linear-gradient(180deg, #9A9B9C 0%, #747679 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
}

.intro-flag > p, .intro-flag > h1, .intro-flag > .intro-flag-actions button  {
  color: white;
}

.intro-flag hr {
  border-color: transparent;
}

.intro-flag p {
  font-size: 32px;
}

.intro-flag h1 {
  font-weight: bold;
}

.intro-flag hr {
  width: inherit;
  margin-top: 30px;
  border-top: 1px grey;
}

.intro-flag-actions {
  display: flex;
  justify-content: left;
  align-items: center;
  width: inherit;
}

/* .intro-flag-actions button {
  background: none;
  color: #000000;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 14px;
} */

img.kr-migration-profile {
  margin-top: -80px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.card-label--with-avatar {
  margin-bottom: 80px;
}

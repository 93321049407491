a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video,
​ html {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1
}

body,
#app {
    min-height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

ol,
ul {
    list-style: none
}

.modal-content ul {
    list-style: inside;
}

.modal-content ul li {
    font-size: 1rem;
    line-height: 1.5;
    color: #4d4f53;
}

blockquote,
q {
    quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

html {
    width: 100%;
    height: 100%;
    font-size: 16px
}

body,
html {
    background-color: #fff
}

section {
    margin: 0;
    width: 100%;
    padding: 0 0 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

section.pb-20 {
    padding-bottom: 20px
}

@font-face {
    font-family: Waleray;
    src: url(../assets/fonts/waleray-bold-webfont.woff2) format("woff2"), url(../assets/fonts/waleray-bold-webfont.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Waleray;
    src: url(../assets/fonts/waleray-bolditalic-webfont.woff2) format("woff2"), url(../assets/fonts/waleray-bolditalic-webfont.woff) format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: Waleray;
    src: url(../assets/fonts/waleray-italic-webfont.woff2) format("woff2"), url(../assets/fonts/waleray-italic-webfont.woff) format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: Waleray;
    src: url(../assets/fonts/waleray-medium-webfont.woff2) format("woff2"), url(../assets/fonts/waleray-medium-webfont.woff) format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Waleray;
    src: url(../assets/fonts/waleray-mediumitalic-webfont.woff2) format("woff2"), url(../assets/fonts/waleray-mediumitalic-webfont.woff) format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: Waleray;
    src: url(../assets/fonts/waleray-regular-webfont.woff2) format("woff2"), url(../assets/fonts/waleray-regular-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Waleray;
    src: url(../assets/fonts/waleray-semibold-webfont.woff2) format("woff2"), url(../assets/fonts/waleray-semibold-webfont.woff) format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Waleray;
    src: url(../assets/fonts/waleray-semibolditalic-webfont.woff2) format("woff2"), url(../assets/fonts/waleray-semibolditalic-webfont.woff) format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap
}

blockquote,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
    color: #4d4f53
}

h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 1.2;
    margin: 0 0 20px
}

h1.m-60 {
    margin: 60px 0
}

h1.mb-60 {
    margin-bottom: 60px
}

h1.mt-40 {
    margin-top: 40px
}

h1.mb-40 {
    margin-bottom: 40px
}

h1.green {
    color: #7ab800
}

b,
strong {
    font-weight: 800
}

p.bold {
    font-weight: 700
}

p.small-text {
    font-size: 14px
}

h3.light,
p.light {
    color: #9a9b9c
}

h3 {
    font-size: 20px;
    margin-bottom: 20px
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.text-left {
    text-align: left
}

.wide-desktop {
    max-width: 440px
}

.strike-through {
    text-decoration: line-through
}

a,
blockquote,
body,
button,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
    font-family: Waleray, sans-serif;
    line-height: 1.2
}

body {
    color: #4d4f53
}

a {
    color: #007ec3;
    text-decoration: none;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

a:hover {
    color: #7ab800;
    text-decoration: underline
}

a.small-text {
    font-size: 14px
}

a.email:before,
a.phone:before {
    margin-right: 10px;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: Font Awesome\ 5 Free;
    font-weight: 900
}

a.phone:before {
    content: "\f879"
}

a.email:before {
    content: "\f0e0"
}

.h2,
h2 {
    font-size: 32px;
    font-size: 2rem;
    margin-bottom: 20px
}

.h2.orange,
h2.orange {
    color: #ff6d22
}

.h2.green,
h2.green {
    color: #7ab800
}

.h2.white,
h2.white {
    color: #fff
}

h3.orange {
    color: #ff6d22
}

h3.sm {
    font-size: .9em
}

h3.lg {
    font-size: 1.8em
}

h3.lg.mt-20 {
    margin-top: 20px
}

p {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem
}

p.white {
    color: #fff;
    font-size: 1.2em
}

p.medium {
    font-weight: 500
}

p.bold {
    font-weight: 600
}

p.black {
    color: #000
}

span.medium {
    font-weight: 500
}

span.bold {
    font-weight: 600
}

span.light {
    opacity: .4
}

.stack-span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.text-blue {
    color: #007ec3
}

.center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.flex-0,
.flex-5,
.flex-10,
.flex-20,
.flex-40 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

.flex-0.column,
.flex-5.column,
.flex-10.column,
.flex-20.column,
.flex-40.column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.flex-5>* {
    margin-right: 5px
}

.flex-5.column>* {
    margin-right: 0;
    margin-bottom: 5px
}

.flex-10>* {
    margin-right: 10px
}

.flex-10.column>* {
    margin-right: 0;
    margin-bottom: 10px
}

.flex-20>* {
    margin-right: 20px
}

.flex-20.column>* {
    margin-right: 0;
    margin-bottom: 20px;
}

.flex-40>* {
    margin-right: 40px;
}

.flex-40.column>* {
    margin-right: 0;
    margin-bottom: 40px;
}

.flex-0>:last-child,
.flex-5>:last-child,
.flex-10>:last-child,
.flex-20>:last-child,
.flex-40>:last-child {
    margin-right: 0;
    margin-bottom: 0
}

.flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.flex-between,
.flex-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.flex-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.flex-between.column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.grid {
    display: grid;
    grid-gap: 15px;
    margin-bottom: 20px;
    width: 100%
}

.grid,
.grid.center-items {
    justify-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.grid.max-width-700 {
    width: 100%;
    max-width: 700px;
    margin: 0 auto
}

.grid.two {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr))
}

.grid.three {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
}

.grid.four {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
}

.grid.five {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
}

.grid.six {
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr))
}

.grid.tab-card {
    justify-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0
}

.grid.tab-card-small {
    grid-template-columns: 1fr;
    grid-gap: 20px
}

.grid.payment-grid {
    justify-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0
}

.grid.payment-grid .text-svg-label {
    margin: 0
}

.grid.shipping-row {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
    padding: 20px 0;
    justify-items: legacy;
    -webkit-box-align: initial;
    -ms-flex-align: initial;
    align-items: normal
}

.grid.shipping-row a {
    text-align: left
}

.grid.hideGridMobile {
    display: none
}

.grid.two .exampleBox {
    background-color: #1c1c1c;
    border-radius: 20px;
    height: 200px;
    width: 100%
}

.grid.three .exampleBox {
    background-color: #210e0e;
    border-radius: 20px;
    height: 200px;
    width: 100%
}

.grid.four .exampleBox {
    background-color: #453333;
    border-radius: 20px;
    height: 200px;
    width: 100%
}

.grid.five .exampleBox {
    background-color: #6c5959;
    border-radius: 20px;
    height: 200px;
    width: 100%
}

.grid.six .exampleBox {
    background-color: #8e7777;
    border-radius: 20px;
    height: 200px;
    width: 100%
}

.icon {
    vertical-align: middle;
    display: inline-block;
    width: 96px
}

.icon.v2 {
    width: auto;
    height: 96px
}

.thumbnail {
    display: inline-block;
    width: 100%;
    min-height: 200px;
    background-position: 50%;
    background-size: cover;
    cursor: pointer;
    position: relative
}

.thumbnail.overlay:after {
    display: inline-block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute
}

.thumbnail.overlay.gradient:after {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .35)), to(transparent));
    background: linear-gradient(0deg, rgba(0, 0, 0, .35), transparent);
    opacity: 1;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease
}

.thumbnail.overlay.dark:after {
    background: #000;
    opacity: 0;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease
}

.thumbnail.expand {
    -webkit-transform: scale(1);
    transform: scale(1);
    z-index: 1;
    position: relative;
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center
}

.thumbnail.expand,
:hover>.thumbnail.expand.expand {
    -webkit-transition: -webkit-transform 1s ease;
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease, -webkit-transform 1s ease
}

:hover>.thumbnail.expand.expand {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.global-alert {
    padding: 15px 20px;
    width: 100%;
    -webkit-box-shadow: 0 0 25px rgba(217, 218, 228, .7);
    box-shadow: 0 0 25px rgba(217, 218, 228, .7);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    position: fixed;
    z-index: 10;
    top: auto;
    bottom: 0;
    right: 0;
    left: 0
}

.global-alert.single-message {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.global-alert.middle {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.global-alert.blue {
    background-color: #badafb
}

.global-alert.blue li,
.global-alert.blue p {
    padding-top: 2px;
    color: #004165
}

.global-alert.blue svg {
    stroke: #004165
}

.global-alert.green {
    background-color: #eafad5
}

.global-alert.green li,
.global-alert.green p {
    padding-top: 2px;
    color: #427730
}

.global-alert.green svg {
    stroke: #427730
}

.global-alert.orange {
    background-color: #f9d0b4
}

.global-alert.orange li,
.global-alert.orange p {
    color: #9a3b26
}

.global-alert.orange>svg {
    fill: #9a3b26
}

.global-alert.orange button>svg {
    stroke: #9a3b26
}

.global-alert.orange a {
    color: #9a3b26;
    font-weight: 600;
    cursor: pointer
}

.global-alert.orange a:hover {
    color: #9a3b26
}

.global-alert ul {
    list-style: disc;
    margin-left: 16px;
    font-size: 16px
}

.global-alert ul li {
    margin-bottom: 5px
}

.global-alert p {
    padding-right: 15px;
    margin: -2px 0 10px;
    color: #004165;
    line-height: 19px;
    font-weight: 500
}

.global-alert .alert-message>:last-child {
    margin-bottom: 0
}

.global-alert>svg {
    margin-right: 15px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.global-alert .error-code {
    font-weight: 500
}

.global-alert .error-code,
.global-alert .secondary-msg {
    font-size: 14px;
    font-size: .875rem
}

.global-alert button {
    background: none;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 0;
    margin: 0
}

.global-alert button:hover {
    background: none
}

.green-alert,
.info-alert,
.warning-alert {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    margin: 0 auto
}

.green-alert p,
.info-alert p,
.warning-alert p {
    font-weight: 600;
    margin: 0;
    padding: 0;
    width: 100%
}

.green-alert svg,
.info-alert svg,
.warning-alert svg {
    margin-right: 15px
}

.warning-alert {
    background: #f9d0b4
}

.warning-alert p {
    color: #9a3b26
}

.warning-alert svg {
    fill: #9a3b26
}

.green-alert {
    background: #eafad5
}

.green-alert p {
    color: #427730
}

.info-alert {
    background: #badafb
}

.info-alert p {
    color: #004165
}

.info-alert svg {
    fill: #004165
}

.button-container * {
    margin: 20px
}

button {
    border: 0
}

button .button-text {
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s
}

a.button,
button {
    padding: 11px 20px 13px;
    background-color: #007ec3;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    border-radius: 30px;
    display: inline-block;
    -webkit-transition: background-color .1s linear;
    transition: background-color .1s linear;
    cursor: pointer
}

a.button:focus,
button:focus {
    outline: none
}

a.button:focus-visible,
button:focus-visible {
    outline: 2px solid #007ec3
}

a.button:active,
a.button:hover,
button:active,
button:hover {
    color: #fff;
    background-color: #7ab800;
    text-decoration: none
}

a.button:focus,
button:focus {
    background-color: #7ab800
}

a.button.disabled,
a.button:disabled,
button.disabled,
button:disabled {
    background-color: #9a9b9c;
    cursor: not-allowed
}

a.button.disabled:hover,
a.button:disabled:hover,
button.disabled:hover,
button:disabled:hover {
    background-color: #9a9b9c
}

a.button.dark-hover:active,
a.button.dark-hover:hover,
button.dark-hover:active,
button.dark-hover:hover {
    background-color: #004165
}

a.button.green,
button.green {
    background-color: #7ab800
}

a.button.green:active,
a.button.green:hover,
button.green:active,
button.green:hover {
    background-color: #007ec3
}

a.button.gray,
button.gray {
    background-color: #9a9b9c
}

a.button.gray:active,
a.button.gray:hover,
button.gray:active,
button.gray:hover {
    background-color: #4d4f53
}

a.button.orange,
button.orange {
    background-color: #ff6d22;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    padding: 11px 20px 13px
}

a.button.orange:hover,
button.orange:hover {
    background-color: #9a3b26
}

a.button.black,
button.black {
    background-color: #000;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    padding: 11px 0;
    width: 100%;
    text-transform: uppercase;
    outline: none;
    border: transparent
}

a.button.black:hover,
button.black:hover {
    background-color: #7ab800
}

a.button.form,
button.form {
    border: none;
    font-family: Waleray, sans-serif;
    font-size: 1em
}

a.button.ghost,
a.button.secondary,
button.ghost,
button.secondary {
    border: 1px solid #007ec3;
    color: #007ec3;
    background-color: transparent;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    padding: 11px 20px 13px
}

a.button.ghost:active,
a.button.ghost:hover,
a.button.secondary:active,
a.button.secondary:hover,
button.ghost:active,
button.ghost:hover,
button.secondary:active,
button.secondary:hover {
    border-color: #7ab800;
    background-color: #7ab800;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    color: #fff
}

a.button.ghost.green,
a.button.secondary.green,
button.ghost.green,
button.secondary.green {
    border-color: #7ab800;
    color: #7ab800
}

a.button.ghost.green:active,
a.button.ghost.green:hover,
a.button.secondary.green:active,
a.button.secondary.green:hover,
button.ghost.green:active,
button.ghost.green:hover,
button.secondary.green:active,
button.secondary.green:hover {
    color: #fff;
    background-color: #7ab800
}

a.button.ghost.gray,
a.button.secondary.gray,
button.ghost.gray,
button.secondary.gray {
    border-color: #9a9b9c;
    color: #9a9b9c
}

a.button.ghost.gray:active,
a.button.ghost.gray:hover,
a.button.secondary.gray:active,
a.button.secondary.gray:hover,
button.ghost.gray:active,
button.ghost.gray:hover,
button.secondary.gray:active,
button.secondary.gray:hover {
    color: #fff;
    border-color: #7ab800
}

a.button i,
button i {
    margin-right: 8px
}

a.button.text-btn,
button.text-btn {
    background: none;
    color: #007ec3;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    display: block;
    padding: 10px 0
}

a.button.text-btn.center-button,
button.text-btn.center-button {
    margin: 0 auto
}

a.button.text-btn:focus,
a.button.text-btn:hover,
button.text-btn:focus,
button.text-btn:hover {
    background: none;
    color: #7ab800;
    text-decoration: underline
}

a.button.text-btn.green,
button.text-btn.green {
    color: #7ab800
}

a.button.text-btn.green:focus,
a.button.text-btn.green:hover,
button.text-btn.green:focus,
button.text-btn.green:hover {
    color: #007ec3
}

a.button.text-btn.bold,
button.text-btn.bold {
    font-weight: 700
}

a.button.card-action-button,
button.card-action-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    background-color: transparent;
    width: 100%
}

a.button.card-action-button.shadow,
button.card-action-button.shadow {
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1)
}

a.button.card-action-button:focus,
a.button.card-action-button:hover,
button.card-action-button:focus,
button.card-action-button:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .2)
}

a.button.card-action-button .iconSVG,
a.button.card-action-button img,
button.card-action-button .iconSVG,
button.card-action-button img {
    width: 78px;
    height: 78px
}

a.button.card-action-button .account-content,
button.card-action-button .account-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-left: 20px
}

a.button.card-action-button .account-content .green,
button.card-action-button .account-content .green {
    color: #7ab800
}

a.button.card-action-button .account-content h4,
a.button.card-action-button .account-content p,
button.card-action-button .account-content h4,
button.card-action-button .account-content p {
    text-align: left;
    padding: 0;
    margin: 0
}

a.button.card-action-button .account-content h4,
button.card-action-button .account-content h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 4px
}

a.button.card-action-button .account-content p,
button.card-action-button .account-content p {
    font-size: 16px;
    color: #747679;
    line-height: 19px
}

.request-submitted {
    height: 100%;
    padding-top: 100px;
}

.read-link {
    color: #007ec3;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px
}

.read-link:hover {
    color: #7ab800
}

.read-link:hover:after {
    margin-left: 10px
}

.read-link:after,
.read-link:hover:after {
    -webkit-transition: margin .1s ease;
    transition: margin .1s ease
}

.read-link:after {
    margin-left: 5px;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    content: "\f054"
}

.back-link {
    color: #007ec3;
    font-weight: 500;
    font-size: 16px
}

.back-link:hover {
    color: #7ab800
}

.back-link:hover:after {
    margin-right: 10px;
    -webkit-transition: margin .1s ease;
    transition: margin .1s ease
}

.back-link:hover:before {
    background-color: #7ab800
}

.back-link:before {
    width: 11px;
    height: 6px;
    display: inline-block;
    content: "";
    -webkit-mask: url(../assets/svg/dropdown.svg) no-repeat 50% 50%;
    mask: url(../assets/svg/dropdown.svg) no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: #007ec3;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    position: relative;
    top: 8px;
    margin-right: 4px
}

a.button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.button .icon,
a.button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.button .icon {
    display: none;
    width: auto;
    stroke: #fff
}

.button .icon,
.icon-left {
    margin-right: 5px
}

.arrow {
    margin-top: 2px
}

.icon .rotate-90-left {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: center;
    transform-origin: center
}

.icon .rotate-90-right {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: center;
    transform-origin: center
}

.rotate-90-left.arrow {
    margin-top: 3px
}

.card--container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.card--containersection:last-of-type {
    padding-bottom: 0
}

.card {
    width: 100%;
    height: 100%;
    border-radius: 17px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    margin-bottom: 0;
    background-color: #fff;
    -webkit-transition: border .2s ease;
    transition: border .2s ease;
    border: 3px solid #fff
}

.card.active {
    border: 3px solid #7ab800
}

.card .inner-content {
    padding: 15px;
    width: 100%;
    height: 100%
}

.card .inner-content>:last-child {
    margin-bottom: 0
}

.card .inner-content .address {
    width: 75%;
    color: #4d4f53
}

.card .inner-content.center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center
}

.card .inner-content .left {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.card .inner-content .right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.card .inner-content .space--between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.card .inner-content .w-max {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.card .inner-content .mb-5 {
    margin-bottom: 5px
}

.card .inner-content .mt-10 {
    margin-top: 10px
}

.card .inner-content .mb-10 {
    margin-bottom: 10px
}

.card .inner-content .mb-20 {
    margin-bottom: 20px
}

.card .inner-content .mt-0 {
    margin-top: 0
}

.card .card-footer {
    border-top: 1px solid #f2f2f2;
    margin-top: auto;
    width: 100%
}

.card.shadow {
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1)
}

.card.wide {
    width: 100%
}

.card .tag {
    font-size: 12px;
    font-size: .75rem;
    background-color: #9a9b9c;
    color: #fff;
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 30px;
    position: absolute;
    top: 15px;
    right: 15px
}

.card.account-card-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.card.account-card-container:focus,
.card.account-card-container:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 0 25px rgba(217, 218, 228, .6);
    box-shadow: 0 0 25px rgba(217, 218, 228, .6)
}

.card.account-card-container .iconSVG,
.card.account-card-container img {
    width: 78px;
    height: 78px
}

.card.account-card-container .account-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-left: 20px
}

.card.account-card-container .account-content .green {
    color: #7ab800
}

.card.account-card-container .account-content h4,
.card.account-card-container .account-content p {
    padding: 0;
    margin: 0
}

.card.account-card-container .account-content h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 4px
}

.card.account-card-container .account-content p {
    font-size: 16px;
    color: #747679;
    width: 90%
}

.card.BlogSumCard {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    -webkit-transition: -webkit-box-shadow .3s ease;
    transition: -webkit-box-shadow .3s ease;
    transition: box-shadow .3s ease;
    transition: box-shadow .3s ease, -webkit-box-shadow .3s ease;
    position: relative;
    overflow: hidden
}

.card.BlogSumCard .content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.card.BlogSumCard h3 {
    margin-top: 0
}

.card.BlogSumCard p {
    color: #747679;
    margin-bottom: 20px
}

.card.BlogSumCard p.tag {
    color: #fff
}

.card.BlogSumCard .thumbnail {
    display: inline-block;
    width: 100%;
    min-height: 200px;
    border-radius: 20px 20px 0 0;
    background-position: 50%;
    background-size: cover;
    cursor: pointer;
    position: relative
}

.card.BlogSumCard:focus,
.card.BlogSumCard:hover {
    -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .2)
}

.card.BlogSumCard:focus .thumbnail:after,
.card.BlogSumCard:hover .thumbnail:after {
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.card.BlogSumCard .date {
    width: 100%;
    padding: 0 20px;
    margin: 0;
    line-height: 3;
    font-size: 14px;
    font-size: .875rem;
    color: #9a9b9c;
    display: inline-block;
    border-top: 1px solid #f2f2f2
}

.card.BlogSumCard .date,
.card.BlogSumCard .read-link {
    margin-top: auto
}

.card.BlogSumCard img.author-img {
    width: 75px;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%;
    top: 200px
}

.card.BlogSumCard img.author-img:first-of-type {
    left: 20px
}

.card.BlogSumCard img.author-img:nth-of-type(2) {
    left: 115px
}

.card.BlogSumCard .content {
    padding: 50px 20px 20px
}

.card.BlogSumCard .content,
.card.BlogSumCard .thumbnail {
    -ms-flex-item-align: start;
    align-self: flex-start
}

.card.sponsor-card {
    border-radius: 17px;
    -webkit-box-shadow: 0 0 25px rgba(217, 218, 228, .6);
    box-shadow: 0 0 25px rgba(217, 218, 228, .6);
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%
}

.card.sponsor-card.image {
    margin-top: 55px
}

.card.sponsor-card.image img {
    width: 110px;
    height: 110px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
    margin-top: -55px;
    bottom: 0;
    padding: 0
}

.card.sponsor-card .sponsor--name {
    font-weight: 500;
    font-size: 20px;
    line-height: .9;
    margin: 20px auto 4px;
    padding: 0 20px
}

.card.sponsor-card .sponsor--id {
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 20px;
    padding: 0 20px;
    text-align: center
}

.card .product--price--wrapper {
    margin-top: 10px;
    margin-bottom: 20px
}

.card .product--price--wrapper .price-size {
    font-weight: 500;
    font-size: 20px;
    color: #545860
}

.card .product--price--wrapper .price-size span {
    margin-bottom: 15px
}

.card .product--price--wrapper .price-size span :last-of-type {
    margin-bottom: 10px
}

.card.icon-card {
    width: 157px;
    height: 157px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    border: 3px solid transparent
}

.card.icon-card.active {
    border: 3px solid #7ab800;
    color: #7ab800
}

.card.icon-card.active span {
    font-weight: 700
}

.card.icon-card.wide {
    height: 136px
}

.card.icon-card span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px
}

.card.icon-card img {
    margin-bottom: 20px;
    width: 70px
}

.card.order-summary {
    width: 100%
}

.card.order-summary p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.card.order-summary p.light {
    color: #9a9b9c
}

.card.order-summary p.bold {
    color: #3f454f;
    font-weight: 600
}

.card.order-summary p.red {
    color: #ba0c2f
}

.card.order-summary .visa {
    margin-top: 10px;
    margin-bottom: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-self: flex-start
}

.card.order-summary .visa img {
    margin: 0
}

.card.order-summary .visa p {
    color: #000;
    margin: 0;
    padding-left: 10px
}

.card.order-summary hr {
    border-top: 1px solid rgba(154, 155, 156, .18)
}

.card.order-summary a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    width: 100%
}

.card .product--detail--container {
    padding: 0;
    margin: 0 auto 20px
}

.card .product--detail,
.card .product--detail--container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.card .product--detail {
    width: 100%;
    margin: 0 auto
}

.card .product--detail.top--item {
    text-align: left
}

.card .product--detail.top--item img {
    width: 92px;
    height: 70px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 15px
}

.card .product--detail.top--item a {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-transform: capitalize
}

.card .product--detail.top--item a.ml-8 {
    margin-left: 8px
}

.card .product--detail img {
    width: 90px;
    height: 90px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 10px
}

.card .product--detail p {
    margin: 0 0 2px;
    width: 100%
}

.card .product--detail p.no-wrap {
    white-space: nowrap
}

.card .center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    margin: 0 auto 20px
}

/* .card .center p {
    margin-bottom: 5px
} */

.card .center a {
    font-size: 14px
}

.card .delete--item {
    height: 30px;
    font-size: 21px;
    width: 30px;
    border-radius: 50%;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    margin-right: 12px;
    border: 2px solid #9a9b9c;
    stroke: #9a9b9c;
    background-color: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 8px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.card .delete--item:hover {
    font-weight: 600;
    stroke: #fff;
    border-color: #4d4f53;
    background-color: #4d4f53;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

.product--card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 3px solid transparent;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    height: 100%
}

.product--card.active {
    border: 3px solid #7ab800
}

.product--card img {
    width: 120px;
    height: 120px;
    -o-object-fit: contain;
    object-fit: contain;
    padding-right: 0;
    margin-bottom: 15px
}

.product--card p {
    width: 100%;
    margin: 0;
    padding: 0
}

.product--card .product-info-wrapper {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.product--card .flex-20 {
    margin-bottom: 20px
}

.product--card .product-info {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
    font-size: 14px
}

.product--card .product-info .product-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    color: #4d4f53;
    margin: 0 0 10px;
    text-align: center
}

.product--card .product-info .product-title.green {
    color: #7ab800
}

.product--card .product-info .product-title span {
    text-transform: uppercase
}

.product--card .product-info strong {
    display: inline-block;
    padding-bottom: 10px;
    font-weight: 400
}

.product--card .product-info ul {
    list-style: none;
    color: #747679;
    text-align: center;
    font-size: 14px;
    border-top: 2px solid #f2f2f2;
    border-bottom: 0 solid transparent;
    width: 100%;
    padding: 10px 0 0;
    margin: 0
}

.product--card .product-info li {
    line-height: 20px
}

.product--card .note {
    font-size: 12px;
    padding: 15px 0 0
}

.product--card p.product-price {
    font-size: 20px;
    line-height: 1;
    text-align: center;
    padding: 20px 0 0;
    border-top: 1px solid #f2f2f2
}

.product--card .card-footer {
    -ms-flex-item-align: end;
    align-self: flex-end
}

.card-label {
    margin-bottom: 20px;
    text-transform: capitalize;
    text-align: center
}

.card-title {
    font-size: 27px
}

.card-label-bottom {
    font-size: 14px;
    font-size: .875rem;
    margin: 0;
    text-align: center;
    padding: 20px 0 0
}

.card .modal__product--card--content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    max-width: 300px;
    margin: 0 auto
}

.card .modal__product--card--content img {
    width: 90px
}

.card .modal__product--card--content .product-info .bold {
    margin-bottom: 10px
}

.card .modal__product--card--content .product-info .ml-minus {
    margin-left: -2px
}

.card .modal__product--card--content .product-info .light {
    margin-bottom: 0
}

.card .modal__product--card--content .product-info .quantity--container {
    display: none
}

.card.active .product-info .quantity--container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 15px 0 0
}

.card.error .card-title {
    border-bottom: none;
    background-color: #f9d0b4;
    padding: 20px;
    border-radius: 15px 15px 0 0
}

.card.error .card-title h2 {
    color: #9a3b26;
    margin: 0
}

.error-items li {
    padding-bottom: 10px
}

.error-items li:last-child {
    padding-bottom: 20px
}

.error-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600
}

.error-item,
.error-item:hover {
    color: #9a3b26
}

.error-item .item-symbol {
    margin-right: 10px
}

.error-item .item-symbol svg {
    fill: #9a3b26
}

.error-item .item-text {
    color: #9a3b26;
    margin-right: 5px
}

.error-item .item-arrow {
    margin-top: -5px
}

.error-item .item-arrow svg {
    stroke: #9a3b26;
    -webkit-transform: rotate(270deg) scale(1.25);
    transform: rotate(270deg) scale(1.25)
}

.form-max {
    width: 100%;
    max-width: 335px;
    margin: 0 auto
}

.form-max>.input-wrapper:last-child {
    margin-bottom: 0
}

.form-max textarea {
    -webkit-box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    border: none;
    padding: 15px;
    font-family: Waleray, sans-serif;
    font-size: 16px
}

input,
label,
select {
    font-family: Waleray, sans-serif;
    font-weight: 400
}

form {
    padding: 0
}

form.width-300 {
    width: 100%;
    margin: auto;
    padding: 0 20px;
    max-width: 100%
}

form.input-width-100 .input-wrapper {
    margin-bottom: 0
}

form.input-width-100 input {
    width: 100%;
    margin-bottom: 0
}

form.input-width-100 .password button {
    right: -80%;
    top: -35px
}

form.input-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px
}

form.padding-20 {
    padding: 0
}

.card .input-wrapper label {
    background: #fff
}

.input-wrapper,
.password-wrapper {
    position: relative;
    width: 100%;
    padding-top: 5px;
    text-align: left
}

.input-wrapper .label,
.input-wrapper label,
.password-wrapper .label,
.password-wrapper label {
    color: #747679;
    font-size: 14px;
    font-size: .875rem;
    position: absolute;
    top: -4px;
    left: 10px;
    padding: 0 5px;
    z-index: 1;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    background: #fff
}

.input-wrapper input,
.password-wrapper input {
    -webkit-appearance: none
}

.input-wrapper input,
.input-wrapper select,
.password-wrapper input,
.password-wrapper select {
    -webkit-box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    padding: 12px 15px 10px;
    color: #000;
    font-size: 16px;
    border: none;
    min-width: 230px;
    width: 100%;
    -webkit-transition: -webkit-box-shadow .2s linear;
    transition: -webkit-box-shadow .2s linear;
    transition: box-shadow .2s linear;
    transition: box-shadow .2s linear, -webkit-box-shadow .2s linear;
    border-radius: 0
}

.input-wrapper input:focus,
.input-wrapper select:focus,
.password-wrapper input:focus,
.password-wrapper select:focus {
    -webkit-box-shadow: inset 0 0 0 2px #7ab800;
    box-shadow: inset 0 0 0 2px #7ab800;
    outline: none;
    -webkit-transition: -webkit-box-shadow .2s linear;
    transition: -webkit-box-shadow .2s linear;
    transition: box-shadow .2s linear;
    transition: box-shadow .2s linear, -webkit-box-shadow .2s linear
}

.input-wrapper input:focus+.label,
.input-wrapper input:focus+label,
.input-wrapper select:focus+.label,
.input-wrapper select:focus+label,
.password-wrapper input:focus+.label,
.password-wrapper input:focus+label,
.password-wrapper select:focus+.label,
.password-wrapper select:focus+label {
    font-weight: 800;
    color: #7ab800
}

.input-wrapper input:focus+.label svg path,
.input-wrapper input:focus+label svg path,
.input-wrapper select:focus+.label svg path,
.input-wrapper select:focus+label svg path,
.password-wrapper input:focus+.label svg path,
.password-wrapper input:focus+label svg path,
.password-wrapper select:focus+.label svg path,
.password-wrapper select:focus+label svg path {
    stroke: #7ab800;
    fill: #7ab800;
    opacity: 1
}

.input-wrapper.error input,
.password-wrapper.error input {
    -webkit-box-shadow: inset 0 0 0 2px #9a3b26;
    box-shadow: inset 0 0 0 2px #9a3b26;
    outline: none;
    -webkit-transition: -webkit-box-shadow .2s linear;
    transition: -webkit-box-shadow .2s linear;
    transition: box-shadow .2s linear;
    transition: box-shadow .2s linear, -webkit-box-shadow .2s linear
}

.input-wrapper.error input+.label,
.input-wrapper.error input+label,
.password-wrapper.error input+.label,
.password-wrapper.error input+label {
    font-weight: 800;
    color: #9a3b26
}

.input-wrapper.error input:focus,
.password-wrapper.error input:focus {
    -webkit-box-shadow: inset 0 0 0 2px #7ab800;
    box-shadow: inset 0 0 0 2px #7ab800;
    outline: none;
    -webkit-transition: -webkit-box-shadow .2s linear;
    transition: -webkit-box-shadow .2s linear;
    transition: box-shadow .2s linear;
    transition: box-shadow .2s linear, -webkit-box-shadow .2s linear
}

.input-wrapper.error input:focus+.label,
.input-wrapper.error input:focus+label,
.password-wrapper.error input:focus+.label,
.password-wrapper.error input:focus+label {
    font-weight: 800;
    color: #7ab800
}

.input-wrapper.error .helper-text,
.password-wrapper.error .helper-text {
    color: #9a3b26;
    font-weight: 600
}

.input-wrapper input::-webkit-input-placeholder,
.password-wrapper input::-webkit-input-placeholder {
    opacity: .4
}

.input-wrapper input::-moz-placeholder,
.password-wrapper input::-moz-placeholder {
    opacity: .4
}

.input-wrapper input:-ms-input-placeholder,
.password-wrapper input:-ms-input-placeholder {
    opacity: .4
}

.input-wrapper input::-ms-input-placeholder,
.password-wrapper input::-ms-input-placeholder {
    opacity: .4
}

.input-wrapper input::placeholder,
.password-wrapper input::placeholder {
    opacity: .4
}

.input-wrapper .select-wrapper,
.password-wrapper .select-wrapper {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative
}

.input-wrapper select,
.password-wrapper select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1.4;
    cursor: pointer;
    background: transparent;
    z-index: 1
}

.input-wrapper select::-ms-expand,
.password-wrapper select::-ms-expand {
    display: none
}

.input-wrapper select+.label,
.input-wrapper select+label,
.password-wrapper select+.label,
.password-wrapper select+label {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 2px;
    cursor: pointer;
    z-index: 0;
    line-height: 1
}

.input-wrapper select:focus+.label,
.input-wrapper select:focus+label,
.input-wrapper select:valid+.label,
.input-wrapper select:valid+label,
.password-wrapper select:focus+.label,
.password-wrapper select:focus+label,
.password-wrapper select:valid+.label,
.password-wrapper select:valid+label {
    top: -6px;
    padding-top: 0;
    -webkit-transform: initial;
    transform: none;
    z-index: 2
}

.input-wrapper select~.arrow,
.password-wrapper select~.arrow {
    position: absolute;
    right: 15px;
    cursor: pointer;
    top: 5px;
    bottom: 0;
    margin: auto
}

.input-wrapper.password,
.password-wrapper .input-wrapper,
.password-wrapper.password,
.password-wrapper .password-wrapper {
    margin-bottom: 0
}

.input-wrapper.password input,
.password-wrapper .input-wrapper input,
.password-wrapper .password-wrapper input,
.password-wrapper.password input {
    padding-right: 71px
}

.input-wrapper.password button,
.password-wrapper .input-wrapper button,
.password-wrapper .password-wrapper button,
.password-wrapper.password button {
    color: #7ab800;
    text-transform: uppercase;
    outline: transparent;
    border: none;
    position: absolute;
    font-size: 14px;
    padding: 10px 18px 10px 5px;
    margin: 3px 0 0;
    background-color: #fff;
    right: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
    border-radius: unset
}

.input-wrapper.password button:active,
.input-wrapper.password button:hover,
.password-wrapper .input-wrapper button:active,
.password-wrapper .input-wrapper button:hover,
.password-wrapper .password-wrapper button:active,
.password-wrapper .password-wrapper button:hover,
.password-wrapper.password button:active,
.password-wrapper.password button:hover {
    color: #007ec3
}

.input-wrapper .helper-text,
.password-wrapper .helper-text {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-family: Waleray, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #747679;
    margin-top: 5px;
    margin-bottom: 0
}

.input-wrapper .combo-box-wrapper,
.password-wrapper .combo-box-wrapper {
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.input-wrapper .combo-box-wrapper label,
.password-wrapper .combo-box-wrapper label {
    top: 16px;
    line-height: 1.2;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.input-wrapper .combo-box-wrapper>svg,
.password-wrapper .combo-box-wrapper>svg {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: -2px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.input-wrapper .combo-box-wrapper.active>svg,
.password-wrapper .combo-box-wrapper.active>svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-top: -3px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.input-wrapper .combo-box-wrapper.active .box-data,
.password-wrapper .combo-box-wrapper.active .box-data {
    max-height: 175px;
    -webkit-transition: max-height .2s ease-out;
    transition: max-height .2s ease-out
}

.input-wrapper .combo-box-wrapper.active label,
.input-wrapper .combo-box-wrapper.complete label,
.password-wrapper .combo-box-wrapper.active label,
.password-wrapper .combo-box-wrapper.complete label {
    top: -4px
}

.input-wrapper .combo-box-wrapper input:focus-within~svg,
.input-wrapper .combo-box-wrapper input:focus~svg,
.password-wrapper .combo-box-wrapper input:focus-within~svg,
.password-wrapper .combo-box-wrapper input:focus~svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-top: -3px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.input-wrapper .combo-box-wrapper input:focus-within~.box-data,
.input-wrapper .combo-box-wrapper input:focus~.box-data,
.password-wrapper .combo-box-wrapper input:focus-within~.box-data,
.password-wrapper .combo-box-wrapper input:focus~.box-data {
    max-height: 175px;
    -webkit-transition: max-height .2s ease-out;
    transition: max-height .2s ease-out
}

.input-wrapper .combo-box-wrapper input:focus+label,
.input-wrapper .combo-box-wrapper input:focus-within+label,
.password-wrapper .combo-box-wrapper input:focus+label,
.password-wrapper .combo-box-wrapper input:focus-within+label {
    top: -4px
}

.input-wrapper .combo-box-wrapper .box-data,
.password-wrapper .combo-box-wrapper .box-data {
    overflow-x: hidden !important;
    height: auto;
    max-height: 0;
    z-index: 10;
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 0 0 10px 10px;
    -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, .25);
    box-shadow: 0 10px 10px rgba(0, 0, 0, .25);
    -webkit-transition: max-height .2s ease-out;
    transition: max-height .2s ease-out;
    position: absolute;
    background-color: #fff
}

.input-wrapper .combo-box-wrapper .initial-prompt,
.password-wrapper .combo-box-wrapper .initial-prompt {
    padding: 20px;
    text-align: center
}

.input-wrapper .combo-box-wrapper .initial-prompt strong,
.password-wrapper .combo-box-wrapper .initial-prompt strong {
    color: #747679
}

.input-wrapper .combo-box-wrapper .initial-prompt svg,
.password-wrapper .combo-box-wrapper .initial-prompt svg {
    top: 2px;
    position: relative;
    fill: #747679
}

.input-wrapper .combo-box-wrapper ul,
.password-wrapper .combo-box-wrapper ul {
    overflow-x: hidden !important;
    color: #000
}

.input-wrapper .combo-box-wrapper ul li,
.password-wrapper .combo-box-wrapper ul li {
    padding: 15px 10px;
    border-bottom: 1px solid rgba(154, 155, 156, .3)
}

.input-wrapper .combo-box-wrapper ul li:hover,
.password-wrapper .combo-box-wrapper ul li:hover {
    color: #427730;
    text-decoration: underline;
    font-weight: 700;
    outline: none
}

.input-wrapper .combo-box-wrapper ul li.selected,
.password-wrapper .combo-box-wrapper ul li.selected {
    color: #427730;
    background: rgba(193, 226, 146, .3)
}

.input-wrapper .select-box-wrapper,
.password-wrapper .select-box-wrapper {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    background-color: #fff
}

.input-wrapper .select-box-wrapper .selector,
.password-wrapper .select-box-wrapper .selector {
    width: 100%;
    position: relative;
    background: none;
    height: 44px;
    -webkit-box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    padding: 12px 15px 10px;
    border-radius: 0;
    -webkit-transition: -webkit-box-shadow .2s linear;
    transition: -webkit-box-shadow .2s linear;
    transition: box-shadow .2s linear;
    transition: box-shadow .2s linear, -webkit-box-shadow .2s linear
}

.input-wrapper .select-box-wrapper .selector .label,
.password-wrapper .select-box-wrapper .selector .label {
    line-height: 1.2;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.input-wrapper .select-box-wrapper .selector .label svg.select--icon,
.password-wrapper .select-box-wrapper .selector .label svg.select--icon {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    margin-right: 4px;
    -webkit-transform: scale(.75);
    transform: scale(.75);
    fill: #747679
}

.input-wrapper .select-box-wrapper .selector:hover .label,
.password-wrapper .select-box-wrapper .selector:hover .label {
    cursor: pointer
}

.input-wrapper .select-box-wrapper .selector:active,
.input-wrapper .select-box-wrapper .selector:focus-within,
.input-wrapper .select-box-wrapper .selector:visited,
.password-wrapper .select-box-wrapper .selector:active,
.password-wrapper .select-box-wrapper .selector:focus-within,
.password-wrapper .select-box-wrapper .selector:visited {
    -webkit-box-shadow: inset 0 0 0 2px #7ab800;
    box-shadow: inset 0 0 0 2px #7ab800;
    outline: none
}

.input-wrapper .select-box-wrapper .selector:active .label,
.input-wrapper .select-box-wrapper .selector:focus-within .label,
.input-wrapper .select-box-wrapper .selector:visited .label,
.password-wrapper .select-box-wrapper .selector:active .label,
.password-wrapper .select-box-wrapper .selector:focus-within .label,
.password-wrapper .select-box-wrapper .selector:visited .label {
    top: -10px;
    background: #fafafa
}

.input-wrapper .select-box-wrapper .selector:active .label svg.select--icon,
.input-wrapper .select-box-wrapper .selector:focus-within .label svg.select--icon,
.input-wrapper .select-box-wrapper .selector:visited .label svg.select--icon,
.password-wrapper .select-box-wrapper .selector:active .label svg.select--icon,
.password-wrapper .select-box-wrapper .selector:focus-within .label svg.select--icon,
.password-wrapper .select-box-wrapper .selector:visited .label svg.select--icon {
    -webkit-transform: rotate(0deg) scale(.75);
    transform: rotate(0deg) scale(.75)
}

.input-wrapper .select-box-wrapper .selector:active svg,
.input-wrapper .select-box-wrapper .selector:focus-within svg,
.input-wrapper .select-box-wrapper .selector:visited svg,
.password-wrapper .select-box-wrapper .selector:active svg,
.password-wrapper .select-box-wrapper .selector:focus-within svg,
.password-wrapper .select-box-wrapper .selector:visited svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.input-wrapper .select-box-wrapper .label,
.password-wrapper .select-box-wrapper .label {
    top: 13px
}

.input-wrapper .select-box-wrapper svg,
.password-wrapper .select-box-wrapper svg {
    position: absolute;
    right: 10px;
    top: 18px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear
}

.input-wrapper .select-box-wrapper li svg {
    position: relative;
    right: 0;
    top: 0;
    margin-right: 10px;
    border: 1px solid #D9DAE4;
}

.input-wrapper .select-box-wrapper .selector-text,
.password-wrapper .select-box-wrapper .selector-text {
    color: #4d4f53;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.input-wrapper .select-box-wrapper.active .selector,
.password-wrapper .select-box-wrapper.active .selector {
    -webkit-box-shadow: inset 0 0 0 2px #7ab800;
    box-shadow: inset 0 0 0 2px #7ab800
}

.input-wrapper .select-box-wrapper.active .selector .label,
.password-wrapper .select-box-wrapper.active .selector .label {
    font-weight: 800;
    color: #7ab800;
    top: -8px
}

.input-wrapper .select-box-wrapper.active .selector .label svg,
.password-wrapper .select-box-wrapper.active .selector .label svg {
    fill: #7ab800
}

.input-wrapper .select-box-wrapper.active .selector svg,
.password-wrapper .select-box-wrapper.active .selector svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.input-wrapper .select-box-wrapper.active ul,
.password-wrapper .select-box-wrapper.active ul {
    max-height: 175px;
    -webkit-transition: max-height .3s ease-out;
    transition: max-height .3s ease-out
}

.input-wrapper .select-box-wrapper.completed .selector,
.password-wrapper .select-box-wrapper.completed .selector {
    -webkit-box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3)
}

.input-wrapper .select-box-wrapper.completed .selector svg,
.password-wrapper .select-box-wrapper.completed .selector svg {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
}

.input-wrapper .select-box-wrapper.completed .selector .label,
.password-wrapper .select-box-wrapper.completed .selector .label {
    top: -8px
}

.input-wrapper .select-box-wrapper.completed .selector:active,
.input-wrapper .select-box-wrapper.completed .selector:focus-within,
.input-wrapper .select-box-wrapper.completed .selector:visited,
.password-wrapper .select-box-wrapper.completed .selector:active,
.password-wrapper .select-box-wrapper.completed .selector:focus-within,
.password-wrapper .select-box-wrapper.completed .selector:visited {
    -webkit-box-shadow: inset 0 0 0 2px #7ab800;
    box-shadow: inset 0 0 0 2px #7ab800
}

.input-wrapper .select-box-wrapper.completed .selector:active svg,
.input-wrapper .select-box-wrapper.completed .selector:focus-within svg,
.input-wrapper .select-box-wrapper.completed .selector:visited svg,
.password-wrapper .select-box-wrapper.completed .selector:active svg,
.password-wrapper .select-box-wrapper.completed .selector:focus-within svg,
.password-wrapper .select-box-wrapper.completed .selector:visited svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.input-wrapper .select-box-wrapper.upward ul,
.password-wrapper .select-box-wrapper.upward ul {
    top: auto;
    bottom: 120%;
    border-radius: 10px 10px 0 0
}

.input-wrapper .select-box-wrapper ul,
.password-wrapper .select-box-wrapper ul {
    overflow-x: hidden !important;
    position: absolute;
    background-color: #fff;
    color: #000;
    top: 104%;
    left: 0;
    right: 0;
    z-index: 10;
    height: auto;
    max-height: 0;
    border-radius: 0 0 10px 10px;
    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25)
}

.input-wrapper .select-box-wrapper ul li,
.password-wrapper .select-box-wrapper ul li {
    padding: 15px 10px;
    border-bottom: 1px solid rgba(154, 155, 156, .3);
    cursor: pointer
}

.input-wrapper.migration .select-box-wrapper ul li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.input-wrapper .select-box-wrapper ul li:focus-within,
.input-wrapper .select-box-wrapper ul li:hover,
.password-wrapper .select-box-wrapper ul li:focus-within,
.password-wrapper .select-box-wrapper ul li:hover {
    color: #427730;
    font-weight: 700;
    outline: none
}

.input-wrapper .select-box-wrapper ul li.selected,
.password-wrapper .select-box-wrapper ul li.selected {
    color: #427730;
    background: rgba(193, 226, 146, .3);
    font-weight: 700
}

.quick-input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.quick-input .quick-input-success,
.quick-input .text-btn {
    font-weight: 700;
    margin-left: 20px
}

.quick-input .quick-input-success {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    font-size: .875rem;
    color: #7ab800;
    text-transform: capitalize
}

.quick-input .quick-input-success .status-circle-s {
    margin-right: 5px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.input-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.input-group>* {
    margin-right: 5px
}

.input-group>:last-child {
    margin-right: 0
}

.input-group .static-value {
    -webkit-box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 12px 15px 10px;
    color: #4d4f53;
    font-size: 16px;
    font-weight: 600;
    border: none;
    -webkit-transition: -webkit-box-shadow .2s linear;
    transition: -webkit-box-shadow .2s linear;
    transition: box-shadow .2s linear;
    transition: box-shadow .2s linear, -webkit-box-shadow .2s linear;
    border-radius: 0;
    margin-top: 5px;
    background-color: rgba(154, 155, 156, .05)
}

.input-group .input-wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1
}

.input-group .input-wrapper>input {
    min-width: 100%
}

.input-group.stack,
.input-group.stack-mobile {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.input-group.stack-mobile .static-value,
.input-group.stack .static-value {
    text-align: center
}

.credit-card-grid {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 2.5fr 1.5fr
}

.credit-card-grid .input-wrapper input {
    min-width: 0
}

.form-buttons {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.form-buttons button:first-child {
    margin-right: 20px
}

.switch--container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.switch--container .switch-text {
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4d4f53
}

.switch--container .switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 30px;
    outline: none;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.switch--container .switch:focus-visible {
    outline: 1px solid #007ec3
}

.switch--container .switch input {
    opacity: 0;
    width: 0;
    height: 0
}

.switch--container .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(154, 155, 156, .2);
    -webkit-transition: .4s;
    transition: .4s
}

.switch--container .switch .slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    left: 0;
    bottom: 0;
    background-color: #9a9b9c;
    -webkit-transition: .4s;
    transition: .4s
}

.switch--container .switch .slider svg {
    -webkit-transition-delay: .6s;
    transition-delay: .6s;
    -webkit-transition: .4s;
    transition: .4s;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: absolute;
    top: 50%;
    left: 50%;
    stroke: hsla(0, 0%, 100%, 0);
    -webkit-transform: translate(-115%, -50%);
    transform: translate(-115%, -50%)
}

.switch--container .switch input:checked+.slider {
    background-color: rgba(122, 184, 0, .2)
}

.switch--container .switch input:checked+.slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    left: 0;
    bottom: 0;
    background-color: #7ab800;
    -webkit-transition: .4s;
    transition: .4s
}

.switch--container .switch input:checked+.slider svg {
    position: absolute;
    top: 50%;
    left: 50%;
    stroke: #fff;
    -webkit-transform: translate(25%, -50%);
    transform: translate(25%, -50%)
}

.switch--container .switch input:checked+.slider:before {
    -webkit-transform: translateX(22px);
    transform: translateX(22px)
}

.switch--container .slider.round {
    border-radius: 30px
}

.switch--container .slider.round:before {
    border-radius: 50%
}

.switch--container.disabled .switch-text {
    opacity: .5
}

.switch--container.disabled .slider {
    cursor: not-allowed
}

.switch--container.disabled .switch input:checked+.slider {
    background-color: rgba(154, 155, 156, .2)
}

.switch--container.disabled .switch input:checked+.slider:before {
    background-color: rgba(154, 155, 156, .4)
}

.switch--container.disabled .switch input:checked+.slider svg {
    stroke: hsla(0, 0%, 100%, .5)
}

.quantity--container {
    width: 105px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 20px 0
}

.quantity--container .digit {
    margin-top: -1px
}

.quantity--container .digit,
.quantity--container input {
    color: #4d4f53;
    font-size: 21px;
    font-weight: 400;
    width: auto;
    text-align: center;
    padding: 0
}

.quantity--container input {
    max-width: 35px;
    border: none;
    outline: none
}

.quantity--container .circle {
    height: 30px;
    font-size: 21px;
    width: 30px;
    border-radius: 50%;
    padding: 0;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #7ab800;
    background-color: transparent;
    stroke: #7ab800
}

.quantity--container .circle:disabled {
    border-color: #9a9b9c;
    stroke: #9a9b9c;
    background-color: transparent
}

.validation-list {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 0;
    opacity: 0;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.validation-list.active {
    padding-top: 15px;
    height: 100%;
    opacity: 1
}

.validation-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    position: relative;
    margin: 0 0 10px;
    padding: 0;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.validation-list li span {
    border: 1px solid #4d4f53;
    background-color: #9a9b9c;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    margin-right: 8px;
    opacity: 1%
}

.validation-list li p {
    font-size: 14px;
    font-size: .875rem;
    margin: 0;
    color: #4d4f53
}

.validation-list li span.valid-status {
    border: 1px solid #4d4f53;
    background-color: #9a9b9c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    opacity: .3;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.validation-list li span.valid-status.complete {
    border: 1px solid #7ab800;
    background-color: #7ab800;
    opacity: 1
}

.validation-list li span.valid-status.complete .valid-complete {
    stroke: #fff
}

.validation-list li span.valid-status.error {
    border: 1px solid #9a3b26;
    background-color: #fff;
    opacity: 1
}

.validation-list li span.valid-status.error .error {
    stroke: #9a3b26;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.validation-list li span.valid-status.display-status {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.blue--switch--container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 20px
}

.blue--switch--container .switch-text {
    margin-top: 8px;
    margin-left: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #4d4f53;
    width: 100%;
    text-align: left
}

.blue--switch--container .switch-text a,
.blue--switch--container .switch-text button {
    text-align: left;
    display: block;
    margin-top: 10px
}

.blue--switch--container .blue--switch {
    position: relative;
    display: inline-block;
    width: 30px;
    min-width: 30px;
    height: 30px;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.blue--switch--container .blue--switch input {
    opacity: 0;
    width: 0;
    height: 0
}

.blue--switch--container .blue--switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    border: 2px solid #007ec3
}

.blue--switch--container .blue--switch .slider svg {
    position: absolute;
    top: 50%;
    left: 50%;
    stroke: transparent;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%)
}

.blue--switch--container .blue--switch input:checked+.slider {
    background-color: #007ec3
}

.blue--switch--container .blue--switch input:checked+.slider svg {
    position: absolute;
    top: 50%;
    left: 50%;
    stroke: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.blue--switch--container .blue--switch input:focus+.slider {
    -webkit-box-shadow: 0 0 1px;
    box-shadow: 0 0 1px
}

.blue--switch--container .blue--switch .slider.round {
    border-radius: 30px
}

.blue--switch--container .blue--switch .slider.round:before {
    border-radius: 50%
}

.radio-outter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.radio-container {
    width: 100%;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    border-radius: 10px;
    outline: none
}

.radio-container:focus-visible,
.radio-container:focus-within,
.radio-container:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
    outline: none
}

.radio-container.null {
    top: -10000px;
    width: 1px;
    height: 1px
}

.radio-container.null,
.radio-container .radio-hidden {
    position: absolute;
    visibility: hidden
}

.radio-container .radio-hidden:focus-within {
    outline: #fff;
    border-color: #fff;
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    position: absolute
}

.radio-container .radio-card,
.radio-container .radio-shipping {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    border: 2px solid #fff;
    border-radius: 10px
}

.radio-container .selected-green {
    color: inherit;
    -webkit-transition: color .2s ease;
    transition: color .2s ease
}

.radio-container .radio-shipping-content {
    border-radius: 10px;
    padding: 10px 20px;
    border: 2px solid transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.radio-container .radio-shipping-content img {
    margin-right: 20px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%)
}

.radio-container .radio-shipping-content p {
    margin-bottom: 0
}

.radio-container .radio-hidden:checked~.radio-shipping-content img,
.radio-container~.radio-card img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
}

.radio-container .radio-hidden:checked~.radio-card,
.radio-container .radio-hidden:checked~.radio-image,
.radio-container .radio-hidden:checked~.radio-shipping-content,
.radio-container.text-svg-label input.radio-hidden:checked~span {
    border: 2px solid #7ab800
}

.radio-container .radio-hidden:checked~.radio-card .selected-green,
.radio-container .radio-hidden:checked~.radio-image .selected-green,
.radio-container .radio-hidden:checked~.radio-shipping-content .selected-green,
.radio-container.text-svg-label input.radio-hidden:checked~span .selected-green {
    color: #7ab800;
    font-weight: 800
}

.radio-container.text-svg-label {
    width: 100%;
    margin-right: 12px;
    border-radius: 10px
}

.radio-container.text-svg-label:last-of-type {
    margin-right: 0
}

.radio-container.text-svg-label span {
    min-height: 50px;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    padding: 10px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    border: 2px solid transparent;
    border-radius: 10px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.radio-container.text-svg-label span img {
    margin-right: 10px;
    max-height: 24px
}

.radio-container.text-svg-label span:focus,
.radio-container.text-svg-label span:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2)
}

.radio-container.text-svg-label span :checked {
    border: 2px solid #7ab800
}

.radio-container .radio-hidden:checked~.radio-image {
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
}

.radio-container .radio-image {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    padding: 10px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    border: 2px solid transparent;
    border-radius: inherit;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    cursor: pointer
}

.radio-container .radio-image.credit-card-svg {
    width: 45px
}

.radio-container .radio-image:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2)
}

.radio-container.account {
    padding: 20px;
    width: 157px;
    height: 137px;
    cursor: pointer
}

.radio-container.account,
.radio-container.account .radio-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.radio-container.account .radio-image {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    width: 75px;
    height: 125px;
    padding: 20px 40px;
    border: 2px solid transparent;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.radio-container.account .radio-image span {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    margin-top: 20px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400
}

.radio-container.account .radio-image:hover {
    color: #7ab800;
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2)
}

.radio-container.account .radio-hidden:checked~.radio-image {
    border: 2px solid #7ab800;
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
}

.radio-container.account .radio-hidden:checked~.radio-image span {
    color: #7ab800;
    font-weight: 600
}

.step-progress-container {
    width: 100%
}

.step-progress-container a.button.back-button {
    color: #007ec3;
    padding: 11px 20px 13px;
    background-color: #007ec3;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    border-radius: 30px;
    -webkit-transition: background-color .1s linear;
    transition: background-color .1s linear
}

.step-progress-container a.button.back-button:focus,
.step-progress-container a.button.back-button:hover {
    background-color: #7ab800;
    text-decoration: none
}

.step-progress-container a.button.back-button .icon {
    stroke: #007ec3
}

.step-progress-container a.button.back-button:hover .icon {
    stroke: #7ab800
}

.progress-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    list-style: none;
    width: 100%;
    padding: 0;
    position: relative;
    margin: 0 auto;
    max-width: none
}

.progress-steps * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.progress-steps li {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    position: relative
}

.progress-steps li:not(:first-child) {
    margin-left: auto
}

.progress-steps li:before {
    position: absolute;
    content: "";
    top: 16px;
    left: -50%;
    width: 100%;
    height: 1px;
    background-color: rgba(154, 155, 156, .25)
}

.progress-steps li:first-child:before {
    content: none
}

.progress-steps li.active:before,
.progress-steps li.complete:before {
    background-color: #007ec3
}

.progress-steps li a {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.progress-steps li a:focus,
.progress-steps li a:hover {
    text-decoration: none
}

.progress-steps li .circle,
.progress-steps li .digit {
    border: 1px solid #007ec3;
    background-color: #fff;
    font-size: 12px;
    color: #007ec3;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 15px;
    position: relative;
    z-index: 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    outline: 2px solid #fff
}

.progress-steps li .text {
    text-align: center;
    color: #9a9b9c;
    padding-top: 6px;
    display: none;
    font-size: 12px
}

.progress-steps li.complete .digit {
    background-color: #007ec3;
    color: #fff
}

.progress-steps li.complete .text {
    color: #9a9b9c
}

.progress-steps li.complete .circle {
    background-color: #007ec3;
    display: inline-block
}

.progress-steps li.complete .circle svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    stroke: #fff
}

.progress-steps li.active .digit {
    background-color: #007ec3;
    color: #fff
}

.progress-steps li.active .text {
    color: #007ec3;
    font-weight: 600
}

.progress-steps li.disabled a {
    pointer-events: none
}

.custom-select {
    position: relative;
    width: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    box-shadow: inset 0 0 0 1px rgba(154, 155, 156, .3);
    -webkit-transition: -webkit-box-shadow .2s linear;
    transition: -webkit-box-shadow .2s linear;
    transition: box-shadow .2s linear;
    transition: box-shadow .2s linear, -webkit-box-shadow .2s linear;
    min-height: 44px
}

.custom-select:focus,
.custom-select:focus-within {
    -webkit-box-shadow: inset 0 0 0 2px #7ab800;
    box-shadow: inset 0 0 0 2px #7ab800;
    outline: none
}

.custom-select:focus-within label,
.custom-select:focus label {
    color: #7ab800;
    font-weight: 600;
    top: -1px;
    left: 10px
}

.custom-select:focus-within svg,
.custom-select:focus svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.custom-select svg.arrow {
    position: absolute;
    right: 14px;
    top: 40%;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease
}

.custom-select select {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important
}

.custom-select select:valid+label {
    top: -8px;
    left: 10px
}

.select-selected {
    width: 100%
}

.select-items div,
.select-selected {
    color: #4d4f53;
    padding: 10px 16px;
    cursor: pointer;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.select-items {
    position: absolute;
    background-color: #fff;
    color: #000;
    top: 104%;
    left: 0;
    right: 0;
    z-index: 10;
    height: 100px;
    overflow-x: hidden;
    border-radius: 0 0 10px 10px;
    -webkit-filter: drop-shadow(0 4px 10px rgba(0, 0, 0, .25));
    filter: drop-shadow(0 4px 10px rgba(0, 0, 0, .25))
}

.select-items div {
    border-bottom: 1px solid rgba(154, 155, 156, .3)
}

.select-items div:focus-visible,
.select-items div:hover {
    color: #427730;
    font-weight: 700;
    text-decoration: underline;
    outline: none
}

.select-hide {
    display: none
}

.same-as-selected {
    color: #427730;
    background: rgba(193, 226, 146, .3)
}

p.address-country {
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px
}

.status-circle-s {
    border: 1px solid #4d4f53;
    background-color: #9a9b9c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    opacity: .3;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.status-circle-s.success {
    border: 1px solid #7ab800;
    background-color: #7ab800;
    opacity: 1
}

.status-circle-s.success svg {
    stroke: #fff
}

.secure-icon-lockup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.secure-icon-lockup img {
    margin-right: 10px;
    width: 24px;
    height: auto
}

.secure-icon-lockup .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 100;
    color: #4d4f53
}

.secure-icon-lockup .content .bold {
    font-weight: 700;
    font-size: 14px
}

.info-box {
    width: auto;
    position: relative;
    padding-bottom: 20px;
    color: #4d4f53
}

.info-box.input-info {
    top: 5px;
    left: 85%;
    width: 30px
}

.info-box.input-info input {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 0
}

.info-box.input-info .info-box-card {
    background-color: #fff;
    color: #747679;
    padding: 20px;
    width: 350px;
    height: 0;
    opacity: 0;
    display: none;
    border-radius: 17px;
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
    position: absolute;
    z-index: 5;
    top: 28px;
    right: -130%;
    -webkit-box-shadow: 0 0 25px #eaeaea;
    box-shadow: 0 0 25px #eaeaea
}

.info-box.input-info .info-box-card input {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 0
}

.info-box.input-info .info-box-card:after {
    content: none
}

.info-box input.info-checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1
}

.info-box .info-label,
.info-box .info-label-close {
    color: #4d4f53;
    cursor: pointer
}

.info-box label.info-label {
    display: inline-block;
    padding: 2px
}

.info-box label.info-label svg {
    top: 6px;
    position: relative
}

.info-box .info-label-close {
    stroke: #fff
}

.info-box .info-checkbox:checked~.info-box-card,
.info-box .info-label:hover~.info-box-card {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    opacity: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.info-box .info-box-card {
    background-color: #007ec3;
    color: #fff;
    padding: 15px;
    width: 100%;
    height: 0;
    opacity: 0;
    display: none;
    border-radius: 17px;
    -webkit-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
    position: absolute;
    z-index: 5;
    top: 100%
}

.info-box .info-box-card:after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    border: 15px solid transparent;
    border-bottom-color: #007ec3
}

.info-box .info-box-card p {
    color: #fff;
    text-align: left;
    margin-right: 23px
}

.info-box .info-box-card p:last-of-type {
    margin-bottom: 0
}

.info-box .info-box-card .close {
    position: absolute;
    top: 0;
    right: 0;
    outline: none;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    margin: 0;
    opacity: .9;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.info-box .info-box-card .close:hover {
    opacity: 1
}

.tabs input.tab-checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1
}

.tabs .tab {
    width: 100%;
    color: #4d4f53;
    overflow: hidden;
    margin-bottom: 10px;
    -webkit-filter: drop-shadow(0 0 5px #eaeaea);
    filter: drop-shadow(0 0 5px #eaeaea)
}

.tabs .tab:last-of-type {
    margin-bottom: 0
}

.tabs .tab .tab-label svg {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    margin-left: auto;
    margin-bottom: auto;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin-top: auto;
    stroke: #9a9b9c
}

.tabs .tab label.tab-label {
    border-left: 2px solid #9a9b9c
}

.tabs .tab.darkblue label.tab-label {
    border-left: 2px solid #004165
}

.tabs .tab.darkblue .tab-label svg {
    stroke: #004165
}

.tabs .tab.blue label.tab-label {
    border-left: 2px solid #007ec3
}

.tabs .tab.blue .tab-label svg {
    stroke: #007ec3
}

.tabs .tab.red label.tab-label {
    border-left: 2px solid #ba0c2f
}

.tabs .tab.red .tab-label svg {
    stroke: #ba0c2f
}

.tabs .tab.orange label.tab-label {
    border-left: 2px solid #ff6d22
}

.tabs .tab.orange .tab-label svg {
    stroke: #ff6d22
}

.tabs .tab.purple label.tab-label {
    border-left: 2px solid #93509e
}

.tabs .tab.purple .tab-label svg {
    stroke: #93509e
}

.tabs .tab.green label.tab-label {
    border-left: 2px solid #7ab800
}

.tabs .tab.green .tab-label svg {
    stroke: #7ab800
}

.tabs .tab.darkgreen label.tab-label {
    border-left: 2px solid #427730
}

.tabs .tab.darkgreen .tab-label svg {
    stroke: #427730
}

.tabs .tab-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    font-size: 16px;
    background: #fff;
    cursor: pointer
}

.tabs .tab-label span {
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset
}

.tabs .tab-label span.blue {
    color: #007ec3
}

.tabs .tab-label span.red {
    color: #ba0c2f
}

.tabs .tab-label span.orange {
    color: #ff6d22
}

.tabs .tab-label span.purple {
    color: #93509e
}

.tabs .tab-label:focus,
.tabs .tab-label:hover {
    background: #f2f2f2
}

.tabs .tab-label svg {
    margin-left: auto;
    margin-bottom: auto;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin-top: auto;
    stroke: #9a9b9c
}

.tabs .tab-content,
.tabs .tab-label svg {
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.tabs .tab-content {
    max-height: 0;
    padding: 0;
    color: #4d4f53
}

.tabs .tab-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1em;
    font-size: .75em;
    background: #fff;
    cursor: pointer
}

.tabs .tab-close:hover {
    background: #343639
}

.tabs input.tab-checkbox:checked+.tab-label svg {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
}

.tabs input.tab-checkbox:checked~.tab-content {
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    padding: 20px 0
}

hr {
    border-color: rgba(154, 155, 156, .18)
}

.loader-ring {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 0
}

.loader-ring div {
    border: 8px solid rgba(122, 184, 0, .15);
    border-top-color: #7ab800;
    border-radius: 50%;
    -webkit-animation: loader-ring 1.5s cubic-bezier(.66, .41, .53, .79) infinite;
    animation: loader-ring 1.5s cubic-bezier(.66, .41, .53, .79) infinite;
    width: 100%;
    height: 100%
}

.loader-ring h2 {
    position: fixed;
    left: -46px;
    padding: 10px 20px;
    background: #fff;
    border-radius: 100px;
    margin: 10px 0
}

button.button-loading {
    position: relative
}

button.button-loading .button-text {
    opacity: 0
}

button.button-loading:after {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border: 5px solid hsla(0, 0%, 100%, .15);
    border-top-color: #fff;
    border-radius: 50%;
    -webkit-animation: loader-ring 1.5s cubic-bezier(.66, .41, .53, .79) infinite;
    animation: loader-ring 1.5s cubic-bezier(.66, .41, .53, .79) infinite
}

button.accordion {
    text-align: left;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 100%;
    color: #4d4f53;
    overflow: hidden;
    margin-bottom: 10px;
    -webkit-filter: drop-shadow(0 0 5px #eaeaea);
    filter: drop-shadow(0 0 5px #eaeaea);
    border-radius: initial;
    border: none;
    border-left: 2px solid #9a9b9c;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

button.accordion:last-of-type {
    margin-bottom: 0
}

button.accordion span.blue {
    color: #007ec3
}

button.accordion span.red {
    color: #ba0c2f
}

button.accordion span.orange {
    color: #ff6d22
}

button.accordion span.purple {
    color: #93509e
}

button.accordion svg {
    -webkit-transition: -webkit-transform 75ms linear;
    transition: -webkit-transform 75ms linear;
    transition: transform 75ms linear;
    transition: transform 75ms linear, -webkit-transform 75ms linear;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    stroke: #9a9b9c;
    justify-self: flex-end;
    margin-left: auto
}

button.accordion.darkblue {
    border-left: 2px solid #004165
}

button.accordion.darkblue svg {
    stroke: #004165
}

button.accordion.blue {
    border-left: 2px solid #007ec3
}

button.accordion.blue svg {
    stroke: #007ec3
}

button.accordion.red {
    border-left: 2px solid #ba0c2f
}

button.accordion.red svg {
    stroke: #ba0c2f
}

button.accordion.orange {
    border-left: 2px solid #ff6d22
}

button.accordion.orange svg {
    stroke: #ff6d22
}

button.accordion.purple {
    border-left: 2px solid #93509e
}

button.accordion.purple svg {
    stroke: #93509e
}

button.accordion.green {
    border-left: 2px solid #7ab800
}

button.accordion.green svg {
    stroke: #7ab800
}

button.accordion.darkgreen {
    border-left: 2px solid #427730
}

button.accordion.darkgreen svg {
    stroke: #427730
}

button.accordion:focus-within,
button.accordion:hover {
    background: #f2f2f2
}

button.accordion+.accordion-content {
    color: #4d4f53;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

button.accordion+.accordion-content .grid {
    display: none
}

button.accordion.is-open {
    margin-bottom: 0
}

button.accordion.is-open svg {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
}

button.accordion.is-open+.accordion-content {
    padding: 20px 0;
    margin-bottom: 40px;
    overflow: visible
}

button.accordion.is-open+.accordion-content .grid {
    display: grid;
    position: relative
}

button.accordion.is-open+.accordion-content .grid:after {
    content: "";
    background: #fff;
    position: absolute;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    -webkit-animation: fadeInAnimation .5s linear;
    animation: fadeInAnimation .5s linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.info-tooltip-wrapper {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    cursor: pointer
}

.info-tooltip-wrapper.center {
    margin: 0 auto
}

.info-tooltip-wrapper p {
    margin: 0
}

.info-tooltip-wrapper .info-button {
    border-left: none;
    width: 34px;
    height: 34px;
    position: relative;
    margin: -5px -5px -5px 0;
    padding: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-filter: none;
    filter: none;
    background: #fff;
    vertical-align: middle
}

.info-tooltip-wrapper .info-button:hover {
    background-color: transparent;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.info-tooltip-wrapper .info-button:hover svg {
    -webkit-transform: unset;
    transform: unset;
    -webkit-transition: none;
    transition: none;
    justify-self: unset;
    margin-left: unset
}

.info-tooltip-wrapper .info-button:focus-visible {
    outline: 1px solid #007ec3;
    background: none
}

.info-tooltip-wrapper .info-button svg {
    stroke: initial;
    -webkit-transform: unset;
    transform: unset;
    -webkit-backface-visibility: hidden
}

.info-tooltip-wrapper .info-tooltip {
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    opacity: 0;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 300px;
    z-index: 5;
    padding: 15px;
    position: absolute;
    background-color: #007ec3;
    color: #fff;
    border-radius: 17px;
    text-align: left;
    font-size: 14px;
    font-weight: 400
}

.info-tooltip-wrapper .info-tooltip.copied {
    background-color: #7ab800;
    color: #fff;
    width: 92px;
    padding: 15px !important
}

.info-tooltip-wrapper .info-tooltip button.close {
    background: none;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: absolute;
    right: 0;
    top: 0;
    border-color: transparent;
    padding: 15px
}

.info-tooltip-wrapper .info-tooltip button.close:focus-visible {
    outline: 1px solid #fff
}

.info-tooltip-wrapper .info-tooltip:after {
    content: "";
    width: 0;
    height: 0;
    margin: auto;
    position: absolute;
    z-index: 1
}

.info-tooltip-wrapper.bottom-tooltip .info-tooltip {
    top: 100%;
    margin: 10px 0 0 2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.info-tooltip-wrapper.bottom-tooltip .info-tooltip:after {
    border-bottom: 10px solid #007ec3;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: 99%;
    left: 0;
    right: 0
}

.info-tooltip-wrapper.top-tooltip.copied .info-tooltip:after {
    border-top: 10px solid #7ab800
}

.info-tooltip-wrapper.top-tooltip .info-tooltip {
    bottom: 100%;
    margin: 0 0 10px 2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.info-tooltip-wrapper.top-tooltip .info-tooltip:after {
    border-top: 10px solid #007ec3;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 99%;
    left: 0;
    right: 0
}

.info-tooltip-wrapper.left-tooltip .info-tooltip {
    top: 50%;
    margin-right: 5px;
    right: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.info-tooltip-wrapper.left-tooltip .info-tooltip:after {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #007ec3;
    top: 0;
    bottom: 0;
    left: 99%
}

.info-tooltip-wrapper.right-tooltip .info-tooltip {
    top: 50%;
    margin-left: 10px;
    left: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.info-tooltip-wrapper.right-tooltip .info-tooltip:after {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #007ec3;
    top: 0;
    bottom: 0;
    right: 99%
}

.info-tooltip-wrapper.is-open .info-tooltip,
.info-tooltip-wrapper:hover .info-tooltip {
    opacity: 1;
    display: inline-block
}

.info-tooltip-wrapper:hover .info-tooltip button.close {
    display: none
}

.info-tooltip-wrapper button.info-button.is-open+.info-box-card,
.info-tooltip-wrapper button.info-button:hover+.info-box-card {
    right: -175%;
    top: 130%
}

.info-tooltip-wrapper button:hover svg {
    -webkit-transform: unset;
    transform: unset;
    -webkit-transition: none;
    transition: none;
    justify-self: unset;
    margin-left: unset
}

.info-tooltip-wrapper button:focus-visible {
    outline: 1px solid #007ec3;
    background: none
}

.info-tooltip-wrapper button svg {
    -webkit-transform: unset;
    transform: unset;
    -webkit-backface-visibility: hidden
}

.info-tooltip-wrapper button+.info-box-card {
    display: none;
    height: 0;
    opacity: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    min-width: 300px;
    max-width: 300px;
    z-index: 5;
    padding: 15px;
    position: fixed;
    overflow: hidden;
    background-color: #007ec3;
    color: #fff;
    border-radius: 17px
}

.info-tooltip-wrapper button+.info-box-card p {
    color: #fff;
    text-align: left;
    margin-right: 23px
}

.info-tooltip-wrapper button+.info-box-card p:last-of-type {
    margin-bottom: 0
}

.info-tooltip-wrapper button+.info-box-card button.close {
    background: none;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: absolute;
    right: 0;
    top: 0;
    border-color: transparent;
    padding: 15px
}

.info-tooltip-wrapper button+.info-box-card button.close:focus-visible {
    outline: 1px solid #fff
}

.info-tooltip-wrapper button+.info-box-card:after {
    content: "";
    position: absolute;
    bottom: 95%;
    left: 76.3%;
    width: 15px;
    height: 15px;
    background: #007ec3;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1
}

.info-tooltip-wrapper button.is-open+.info-box-card,
.info-tooltip-wrapper button:hover+.info-box-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    min-height: 170px;
    overflow: visible;
    position: absolute;
    top: 95%;
    opacity: 1;
    right: -20%
}

.info-tooltip-wrapper button+.accordion-content {
    color: #4d4f53;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.info-tooltip-wrapper button+.accordion-content .grid {
    display: none
}

.info-tooltip-wrapper button.is-open {
    margin-bottom: 0
}

.info-tooltip-wrapper button.is-open svg {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
}

.info-tooltip-wrapper button.is-open+.accordion-content {
    padding: 20px 0;
    margin-bottom: 40px;
    overflow: visible
}

.text-to-define {
    border-bottom: 1px dotted;
    cursor: help;
    position: relative
}

.text-to-define:hover .text-definition {
    visibility: visible;
    opacity: 1
}

.text-definition {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: #007ec3;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    min-width: 280px;
    margin: 0 0 5px;
    padding: 20px;
    max-width: 300px;
    bottom: 100%;
    left: 0;
    text-align: left;
    font-size: 16px;
    line-height: 1.2;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    z-index: 5
}

.address {
    padding-left: 10px;
    border-left: 1px solid #9a9b9c;
    display: inline-block
}

body.modal-open {
    overflow: hidden
}

.modal--wrapper {
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease;
    background-color: transparent;
    position: fixed;
    opacity: 0;
    z-index: 200;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background-color: rgba(77, 79, 83, .8);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    padding: 20px
}

.modal--wrapper.confirmation-modal {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.modal--wrapper.confirmation-modal,
.modal--wrapper.profile-modal {
    overflow-y: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.modal--wrapper.profile-modal {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.modal--wrapper.loader-state {
    background-color: hsla(0, 0%, 100%, .9)
}

.modal {
    background-color: #fff;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease;
    padding: 20px 0;
    margin: 0 auto;
    border-radius: 20px;
    z-index: -1;
    width: 100%;
    position: relative
}

.confirmation-modal .modal {
    max-height: 100%
}

.confirmation-modal .modal .loading-modal {
    height: 300px;
    max-height: 100%;
}

.confirmation-modal .modal,
.modal .center,
.modal .modal-footer,
.modal .modal-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.modal .center,
.modal .modal-footer,
.modal .modal-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.modal .modal-footer.double-button-modal {
    gap: 30px;
}

.modal .modal-title {
    padding: 0 30px 30px 20px
}

.modal .modal-title.center {
    text-align: center
}

.modal .modal-title h2 {
    margin-bottom: 0
}

.modal .modal-content {
    overflow-y: auto;
    padding: 10px 20px 20px
}

.modal .modal-content.center {
    text-align: center;
    padding: 10px 40px 20px
}

.modal .modal-content.top {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.modal .modal-content .error-text {
    color: #9a3b26;
}

.modal .modal-footer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 20px 0 0
}

.modal .modal-footer .secondary {
    margin-right: 20px
}

.modal>.inner-content {
    padding: 20px
}

.modal .order-cell {
    border-bottom: 1px solid #ededed;
    padding: 10px 0
}

.modal .order-cell:first-of-type {
    border-top: 1px solid #ededed
}

.modal .order-cell:last-of-type {
    border-bottom: 0
}

.modal .modal-close {
    padding: 20px;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: stroke .2s ease;
    transition: stroke .2s ease
}

.modal .modal-close,
.modal .modal-close:focus,
.modal .modal-close:hover {
    background-color: transparent
}

.action-modal-content {
    position: relative;
    opacity: 1;
    -webkit-transition: opacity .2s;
    transition: opacity .2s
}

.action-modal-content.button-loading {
    opacity: .3
}

.action-modal-content.button-loading:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent
}

.fadeIn {
    opacity: 1;
    z-index: 2;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.fade-in {
    opacity: 1;
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease
}

.fadeOut {
    opacity: 0;
    z-index: 0;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.mb-0 {
    margin-bottom: 0
}

.mb-5 * {
    margin-bottom: 5px
}

.mb-10 {
    margin-bottom: 10px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-40 {
    margin-bottom: 40px
}

.mt-0 {
    margin-top: 0
}

.mt-10 {
    margin-top: 10px
}

.mt-20 {
    margin-top: 20px
}

.mt-40 {
    margin-top: 40px
}

.mr-20 {
    margin-right: 20px
}

.hideMobile,
.hideMobile.flex-20 {
    display: none
}

@media (min-width:650px) {
    .grid.tab-card-small {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
    }

    form.width-300 {
        padding: initial;
        max-width: 300px
    }
}

@media (min-width:768px) {
    .grid {
        grid-gap: 20px
    }

    .grid.tab-card-small {
        grid-gap: 35px
    }

    .grid.payment-grid {
        grid-template-columns: 1fr 1fr 1fr
    }

    .grid.shipping-row {
        grid-template-columns: 1.2fr 1fr 1fr
    }

    .grid.shipping-row a {
        text-align: right
    }

    .grid.hideGridMobile {
        display: grid
    }

    .global-alert {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 375px;
        position: absolute;
        right: 20px;
        top: 100px;
        left: auto;
        bottom: auto;
        border-radius: 10px
    }

    .button .icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .card .inner-content {
        padding: 20px
    }

    form.input-grid {
        grid-template-columns: 1fr 1fr
    }

    form.padding-20 {
        padding: 20px
    }

    .input-group.stack-mobile {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .input-group.stack-mobile .static-value {
        text-align: left
    }

    .blue--switch--container .blue--switch {
        -webkit-transform: scale(.83);
        transform: scale(.83)
    }

    .step-progress-container a.button.back-button {
        padding: initial;
        background: transparent;
        color: #007ec3
    }

    .step-progress-container a.button.back-button:focus,
    .step-progress-container a.button.back-button:hover {
        background: none;
        color: #7ab800;
        text-decoration: underline
    }

    .progress-steps {
        margin: 20px auto;
        max-width: 75%
    }

    .modal--wrapper {
        padding: 40px 20px
    }

    .modal {
        padding: 40px;
        max-width: 600px
    }

    .modal .modal-title {
        padding: 0 20px 30px
    }

    .hideMobile {
        display: initial
    }

    .hideMobile.flex-20 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    #bank-information,
    #address-section {
        scroll-margin-top: 100px;
        scroll-behavior: smooth;
    }
}

@media (min-width:1024px) {
    .product--card {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .product--card img {
        padding-right: 10px;
        margin-bottom: 0
    }

    .product--card .product-info-wrapper,
    .product--card .product-info .product-title,
    .product--card .product-info ul {
        text-align: left
    }

    .product--card .product-info li {
        max-width: 170px
    }

    .switch--container.right-aligned {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .progress-steps li .text {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex
    }
}

@media (max-width:1023px) {
    .product--card .flex-20 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .product--card .flex-20>* {
        margin-right: 0
    }

    .product--card .product-info {
        width: 100%;
        text-align: center
    }
}

@media (max-width:768px) {
    .info-tooltip-wrapper.mobile-left-tooltip .info-tooltip {
        bottom: 100%;
        margin: 0 0 10px 2px;
        right: -10px;
        left: auto;
        -webkit-transform: initial;
        transform: none
    }

    .info-tooltip-wrapper.mobile-left-tooltip .info-tooltip:after {
        border-top: 10px solid #007ec3;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        top: 99%;
        left: auto;
        right: 12px
    }
}

@media (max-width:767px) {
    .modal .modal-title h2 {
        font-size: 1.7rem
    }

    .modal .modal-footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .modal .modal-footer .secondary {
        margin-top: 20px;
        margin-right: 0
    }
}

@media (max-width:449px) {
    .grid.tab-card {
        grid-template-columns: 1fr
    }

    .radio-container .radio-shipping-content p {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

@-webkit-keyframes loader-ring {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes loader-ring {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@-webkit-keyframes fadeInAnimation {
    0% {
        opacity: 1;
        z-index: 999
    }

    to {
        opacity: 0;
        z-index: -1
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 1;
        z-index: 999
    }

    to {
        opacity: 0;
        z-index: -1
    }
}